body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-background: #1677ff;
  --secondary-color: #2ecc71;
  --text-color: white;
  --font-size: 16px;
}

.ant-table-row .ant-table-cell-row-hover {
  background-color: #bae0ff !important;
}

.ant-table-wrapper .ant-table,
.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>td,
.ant-table-wrapper .ant-table-container .ant-table-header,
.ant-table-wrapper .ant-table-container .ant-table-body {
  border-radius: 0 !important;
}

.ant-table-wrapper .ant-table-container {
  border-radius: 0 !important;
}

.ant-card {
  border-radius: 0 !important;
}

.ant-btn {
  border-radius: 0 !important;
}

input {
  border-radius: 0 !important;
}

.ant-picker {
  border-radius: 0 !important;
}

.ant-input-affix-wrapper {
  border-radius: 0 !important;
}

.ant-select-single .ant-select-selector {
  border-radius: 0 !important;
}

.ant-tag {
  border-radius: 0 !important;
}

.oi-select-header {
  border-radius: 0 !important;
}

.oi-footer {
  border-radius: 0 !important;
}

/* :where(.css-dev-only-do-not-override-3rel02).ant-table-wrapper .ant-table.ant-table-small .ant-table-title,
:where(.css-dev-only-do-not-override-3rel02).ant-table-wrapper .ant-table.ant-table-small .ant-table-footer,
:where(.css-dev-only-do-not-override-3rel02).ant-table-wrapper .ant-table.ant-table-small .ant-table-cell,
:where(.css-dev-only-do-not-override-3rel02).ant-table-wrapper .ant-table.ant-table-small .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-3rel02).ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>th,
:where(.css-dev-only-do-not-override-3rel02).ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>td,
:where(.css-dev-only-do-not-override-3rel02).ant-table-wrapper .ant-table.ant-table-small tfoot>tr>th,
:where(.css-dev-only-do-not-override-3rel02).ant-table-wrapper .ant-table.ant-table-small tfoot>tr>td {
  padding: 4px 4px !important;
} */

:where(.css-dev-only-do-not-override-3rel02).ant-modal .ant-modal-content {
  border-radius: 0 !important;
}

:where(.css-dev-only-do-not-override-3rel02).ant-input-number .ant-input-number-input {
  border-radius: 0 !important;
}

.ant-input-number {
  border-radius: 0 !important;
}

:where(.css-dev-only-do-not-override-3rel02).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
:where(.css-dev-only-do-not-override-3rel02).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item,
:where(.css-dev-only-do-not-override-3rel02).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
:where(.css-dev-only-do-not-override-3rel02).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  border-radius: 0 !important;
}

:where(.css-dev-only-do-not-override-3rel02).ant-dropdown .ant-dropdown-menu,
:where(.css-dev-only-do-not-override-3rel02).ant-dropdown-menu-submenu .ant-dropdown-menu {
  border-radius: 0 !important;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-dropdown {
  border-radius: 0 !important;
}

.ant-input-number {
  border-radius: 0 !important;
}

/* :where(.css-dev-only-do-not-override-3rel02).ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>.ant-table-measure-row td {
  padding: 0 !important;
} */
.ant-btn-primary:not(.ant-btn-dangerous) {
  background-color: var(--primary-background) !important;
  border-color: var(--primary-background) !important;
  color: var(--text-color) !important;
}
.ant-radio-group-solid .ant-radio-button-wrapper {
  border-radius: 0 !important;
}
.ant-modal .ant-modal-content{
  border-radius: 0 !important;
}