.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-selected::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-selected::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-selected::after {
    border-bottom-width: 2px;
    border-bottom-color: #ffffff;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-selected,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-selected {
    color: #ffffff;
    background-color: transparent;
}

.ant-menu-light .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
    color: #fff;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item:hover::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-item-active::after {
    border-bottom-color: #fff;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px #B9b9b9;
    border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #B9b9b9;
    border-radius: 3px;
}
.custom-row{
    height: calc(100vh - 60px);
    margin-right: 0 !important;
}
.custom-col{
    height: calc(100vh - 76px);
    overflow-y: auto;
}
.custom-ant-card{
    height: calc(100vh - 76px);
}
.custom-ant-card .ant-card-body{
    height: calc(100% - 56px);
    overflow-y: auto;
}
.custom-ant-card .ant-card-actions{
    text-align: center;
    position: sticky;
    bottom: 0;
    background: white;
}
.ant-divider-inner-text{
    padding-inline: 8px !important;
}