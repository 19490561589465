thead[class*="ant-table-thead"] th{
    background-color: #2462A3 !important;
    color: white !important;
}
thead td[class*="ant-table-selection-column"] {
    background-color: #2462A3 !important;
    color: white !important;
}
thead td[class*="ant-table-cell ant-table-cell-scrollbar"]{
    background-color: #2462A3 !important;
}
.table-row-light {
    background-color: #ffffff;
}
.table-row-dark {
    background-color: #fbfbfb;
}
.table-row-grey {
    background-color: #EBEBEB;
}
.table-row-red {
    background-color: #fb4b50;
    color: white;
}
.table-row-green {
    background-color: #d1f7c4
}
.table-row-yellow {
    background-color: #fdfdb4
}

thead th[class*= "yellow"]{
    background-color: #f7ac27 !important;
}

thead th[class*= "red"]{
    background-color: #fb4b50 !important;
}

.no-hover .ant-table-cell-row-hover {
    background-color: transparent !important;
}
.your-table table {
    width: auto;
    min-width: unset !important;
}